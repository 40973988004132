















































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type News = {
  id: number;
  name: string;
  image: string;
  description: string;
  reading_date: number;
};

@Component({
  filters: {
    dispatch_time(value: string, appLanguage: string) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .format("Do MMMM, Y");
    },

    strip_tags(value: string) {
      if (!value) return;

      return value.replace(/(<([^>]+)>)/gi, "");
    }
  }
})
export default class NewsComponent extends Vue {
  @Prop(Object) readonly news!: News;

  private newsDialog = false;

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get newsImage() {
    if (this.news.image == null) return null;

    const mode = this.$store.getters["app/mode"];
    let url = process.env.VUE_APP_CORE_URL;

    if (mode == "Dev") url = process.env.VUE_APP_CORE_DEV_URL;
    if (mode == "Test") url = process.env.VUE_APP_CORE_TEST_URL;

    return `${url}/storage/${this.news.image}`;
  }

  readNews(): void {
    this.newsDialog = true;

    if (!this.news.reading_date) {
      this.$store.dispatch("news/update", { id: this.news.id }).then(() => {
        this.$store.commit("news/setAsRead", this.news);
      });
    }
  }
}
